import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from '../services/backend.service';
import { AuthGuardService } from '../services/auth.guard.service';
import { UserService } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifyService } from '../services/notify.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  test: string;
  public form =  {
    username:null,
    password: null
  };

  formularwerte: { value: any, valid: boolean } = {} as { value: any, valid: boolean };

  constructor(
    private backendService: BackendService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private http:HttpClient,
    public router: Router,
    public authGuardService: AuthGuardService,
    public notifyService: NotifyService
  ) {
    this.formularwerte = this.formBuilder.group({
                name: ['', Validators.required],
                password: ['', Validators.required],
            });
   }

  login({ value, valid }: { value: any, valid: boolean }): void
  {
    if (valid) {
            this.backendService.login(value.name, value.password)
            .subscribe((angemeldet: Boolean) => {
                if (angemeldet === true) {
                    this.router.navigate([this.backendService.redirectUrl]);
                    location.reload();
                    this.backendService.redirectUrl = '/';
                }
                else {
                  this.notifyService.snotifyError("Login fehlgeschlagen")
                }
            });

        }
        else {
          this.notifyService.snotifyError("Login fehlgeschlagen")
        }


  }

  ngOnInit() {

    this.authGuardService.checkLogin('/').subscribe((angemeldet: Boolean) => {
        if (angemeldet === true) {
            this.router.navigate([this.backendService.redirectUrl]);
            this.backendService.redirectUrl = '/';
        }
    });
  }

}
