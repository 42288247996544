import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TemplatePageService} from '../../../../services/template-page.service';
import { TemplatePage } from '../../../../models/templatePage';
import { Template } from '../../../../models/template';
import {TemplateService} from '../../../../services/template.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-template-pages-edit',
  templateUrl: './admin-template-pages-edit.component.html',
  styleUrls: ['./admin-template-pages-edit.component.css']
})
export class AdminTemplatePagesEditComponent implements OnInit {

  params: any;
  template_id: any;
  templatePage_id: any;
  templatePage: any;
  templatePages: Observable<TemplatePage[]>;
  status: any;
  message: any;
  templates:  Observable<Template[]>;


  constructor(
    private activatedRoute: ActivatedRoute,
    private templatePageService: TemplatePageService,
    private templateService: TemplateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.template_id = params['template_id']);
    this.params = this.activatedRoute.params.subscribe(params => this.templatePage_id = params['id']);
    this.templates = this.templateService.getTemplates();
    this.templatePages = this.templatePageService.getTemplatePages(this.template_id);
   this.templatePage = this.templatePageService.getTemplatePage(this.templatePage_id).subscribe(
     data => {
       this.templatePage.description = data['description'];
       this.templatePage.title = data['title'];
       this.templatePage.maxNumber = data['maxNumber'];
       this.templatePage.id = data['id'];
       this.templatePage.imageLink = data['imageLink'];
       this.templatePage.active = data['active'];
       this.templatePage.type = data['type'];
       this.templatePage.template = data['template'];

       if (this.templatePage.mainTemplatePage == null)
       {
         this.templatePage.mainTemplatePage = 0;
       }
     },

     error => console.log(<any>error));
  }

  ngOnDestroy() {
   this.params.unsubscribe();
 }

 updateTemplatePage(templatePage) {
   this.templatePageService.updateTemplatePage(templatePage, this.template_id)
   .subscribe(
     templatePage => {
       this.status = 'success';
       this.message = templatePage['message'];
       this.router.navigate(['/admin/template/', this.template_id]);
     },
     error => {
       console.log(<any>error);
       this.status = 'error';
       this.message = error['message'];
     });
   }

}
