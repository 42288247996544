<div class="mt-4 col-lg-6 offset-lg-3">
  <div class="card">
    <div class="card-header">Login</div>
    <div class="card-body">
      <form (ngSubmit)="login(formularwerte)" [formGroup]="formularwerte">
    <div class="form-group col-lg-12">
      <label for="inputEmail3" class="col-sm-4 col-form-label">E-Mail-Adresse</label>
      <div class="col-sm-12">
        <input autofocus type="email" name="name" class="form-control" id="inputEmail3" placeholder="E-Mail-Adresse" formControlName="name" required>
      </div>
    </div>
    <div class="form-group col-lg-12">
      <label for="inputPassword3" class="col-sm-4 col-form-label">Passwort</label>
      <div class="col-sm-12">
        <input type="password" name="password" class="form-control" id="inputPassword3" placeholder="Passwort" formControlName="password" required>
      </div>
    </div>
    <div class="form-group col-lg-12">
      <div class="col-lg-12">
        <button type="submit" class="btn btn-primary my-3 col-12 float-left col-sm-5" [disabled]="!formularwerte.valid">Einloggen</button>
        <a [routerLink]="['/register']" class="btn btn-outline-secondary my-3 float-right col-12 col-sm-5">Registrieren</a>
        <a [routerLink]="['/forgotPassword']" class="btn btn-light col-12 col-sm-6 offset-sm-3">Passwort vergessen?</a>
      </div>
    </div>
  </form>
</div>
  </div>
</div>
