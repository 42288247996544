import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Settings } from './classes/settings';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    today = Date.now();
    version: any;

  constructor() { }

  ngOnInit() {
    this.version = Settings.version;
  }

}
