<div class="jumbotron">
  <h1 class="display-4">Feld erstellen</h1>
</div>
<div class="col-md-10 col-md-offset-1">
  <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
  <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
  <div class="well well bs-component">
    <form novalidate class="form-horizontal" (ngSubmit)="createTemplateField(createTemplateFieldForm.value)" #createTemplateFieldForm="ngForm">
      <fieldset>
        <div class="form-group">
          <label for="title" class="col-lg-2 control-label">Feldname</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" id="title" name="title" placeholder="Feldname" ngModel #title="ngModel" required>
            <div *ngIf="title.errors?.required && title.dirty" class="alert alert-danger">
              Feldname ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="description" class="col-lg-2 control-label">Beschreibung</label>
          <div class="col-lg-10">
            <textarea class="form-control" rows="3" id="description" name="description" ngModel #description="ngModel"></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="type" class="col-lg-2 control-label">Typ</label>
          <div class="col-lg-10">
            <select class="form-control" rows="2" id="type" name="type" [ngModel]=null ngModel #type="ngModel" required>
              <option [value]="null" [selected] disabled hidden>Bitte auswählen</option>
              <option value="text">Textzeile</option>
              <option value="textarea">Mehrzeiliger Text</option>
              <option value="number">Zahl</option>
              <option value="option">Checkboxen (opt. Mehrfachauswahl)</option>
              <option value="choice">Auswahl (keine Mehrfachauswahl)</option>
            </select>
          </div>
        </div>
        <div class="form-group" *ngIf="type.value=='option' || type.value=='choice' ">
          <label for="choices" class="col-lg-10 control-label">Auswahlmöglichkeiten</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" id="choices" name="choices" placeholder="Auswahlmöglichkeiten" ngModel #choices="ngModel" required>
            <div class="alert alert-info">
              Mehrere Werte müssen mit einem Komma getrennt werden
            </div>
            <div *ngIf="choices.errors?.required && choices.dirty" class="alert alert-danger">
              Auswahlmöglichkeiten ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="required" class="col-lg-2 control-label">Pflichtfeld</label>
          <div class="col-lg-10">
            <select class="form-control" rows="2" id="required" name="required" [ngModel]=null ngModel #required="ngModel" required>
              <option [value]="null" [selected] disabled hidden>Bitte auswählen</option>
              <option value="1">Ja</option>
              <option value="0">Nein</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <div class="col-lg-10 col-lg-offset-2">
            <a [routerLink]="['/admin/template/', template_id]" class="btn btn-default">Abbrechen</a>
            <button type="submit" class="btn btn-primary" [disabled]="createTemplateFieldForm.invalid">Erstellen</button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
