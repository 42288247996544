import { Component, OnInit } from '@angular/core';
import {TemplatePageService} from '../../../../services/template-page.service';
import {TemplateService} from '../../../../services/template.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Template } from '../../../../models/template';
import { TemplatePage } from '../../../../models/templatePage';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-template-pages-create',
  templateUrl: './admin-template-pages-create.component.html',
  styleUrls: ['./admin-template-pages-create.component.css']
})
export class AdminTemplatePagesCreateComponent implements OnInit {

  template_id: any;
  params: any;
  status: any;

  template:  Observable<Template>;
  templatePages:  Observable<TemplatePage[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private templatePageService: TemplatePageService,
    private templateService: TemplateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.template_id = params['template_id']);
    this.template = this.templateService.getTemplate(this.template_id);
    this.templatePages = this.templatePageService.getTemplatePages(this.template_id);
  }

  createTemplatePage(templatePage) {
       templatePage.template = this.template_id;
   this.templatePageService.addTemplatePage(this.template_id, templatePage)
   .subscribe(
     templatePage => {
       this.router.navigate(['/admin/template', this.template_id]);
     },
     error => console.log(<any>error)
   );
 }

}
