import { Component, OnInit, Input } from '@angular/core';
import { Event } from '../../../models/event';
import {ActivatedRoute} from '@angular/router';
import { RegistrationService } from '../../../services/registration.service';
import { Registration } from '../../../models/registration';
import { EventService } from '../../../services/event.service';
import { BezirkService } from '../../../services/bezirk.service';
import { StammService } from '../../../services/stamm.service';
import { TemplatePageService } from '../../../services/template-page.service';
import { SubscriptionService } from '../../../services/subscription.service';
import { UserService } from '../../../services/user.service';
import { PfadiAktion2024Service } from '../../../services/pfadi-aktion-2024.service';
import {Observable} from 'rxjs';
import { User } from '../../../models/user';
import { Router} from '@angular/router';
import { Subscription } from '../../../models/subscription';
import { NotifyService } from '../../../services/notify.service';
import { ExportService } from '../../../services/export.service';


@Component({
  selector: 'app-pfadi-aktion2024',
  templateUrl: './pfadi-aktion2024.component.html',
  styleUrls: ['./pfadi-aktion2024.component.css']
})
export class PfadiAktion2024Component implements OnInit {

  event_id: any;
  registration_id: any;
  subscription_id: any;
  params: any;
  event:  any;
  stamm:  any;
  bezirk:  any;
  registration: any;
  progress: any;
  user: any;
  templatePages: any;
  status: any;
  templatePage: any;
  templatePage_id: any;
  subscription: any;
  subscription_fotoerlaubnis: any;
  leiter: any;
  tns: any;
  closed: any;
  button: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private bezirkService: BezirkService,
    private eventService: EventService,
    private registrationService: RegistrationService,
    private stammService: StammService,
    private subscriptionService: SubscriptionService,
    private templatePageService: TemplatePageService,
    private userService: UserService,
    private router: Router,
    private notifyService: NotifyService,
    private exportService: ExportService,
    private pfadiaktion2024service: PfadiAktion2024Service
  ) { }

  export() {
    return this.exportService.exportData(this.tns, 'csv');
}

AddOrUpdateSubscription(subscriptiondata) {
  this.subscription = new Subscription()
  this.subscription.data = subscriptiondata

  if (this.subscription_id !== undefined) {
    this.subscription.id = this.subscription_id
    this.pfadiaktion2024service.updateSubscription(this.subscription)
      .subscribe(
        subscription => {
          this.notifyService.snotifySuccess("Anmeldung erfolgreich gespeichert!")
          this.router.navigate(['/events/custom/pfadiaktion2024/registrations'])
          .then(() => {
            window.location.reload();
          });
        },
        error => {
          this.notifyService.snotifyError("Anmeldung fehlerhaft!")
        }
      )
  } 
  else {
    this.pfadiaktion2024service.addSubscription(this.subscription)
      .subscribe(
        subscription => {
          this.notifyService.snotifySuccess("Anmeldung erfolgreich angelegt!")
          this.router.navigate(['/events/custom/pfadiaktion2024/registrations']).then(() => {
            window.location.reload();
          });
        },
        error => {
          this.notifyService.snotifyError("Anmeldung fehlerhaft!")
        }
      )
  }
}

ngOnInit() {

  this.closed=0;

  this.subscription = new Subscription()

  this.event = new Array()
  this.event.title = "Diözesanaktion der Pfadi-Stufe"
  this.event.imageLink = "https://anmeldung.dpsg-essen.de/assets/images/pfadiaktion_2024.jpg";
  this.event.active = 1;

  this.user = this.userService.getActualUser().subscribe(
    user => {
      this.user = user;
      this.user.id = this.user['id'];
      this.user.surname = this.user['surname'];
      this.user.givenname = this.user['givenname'];
      this.user.email = this.user['email'];
      this.user.stamm = this.user['stamm'];


      this.subscription = this.pfadiaktion2024service.getSubscription().subscribe(
        data => {
          this.subscription = data;
          this.subscription_id = this.subscription['id'];

          this.pfadiaktion2024service.getTNsofUser()
          .subscribe(data => {
            this.tns = data
          })

        },
        error => {
          console.log(<any>error)
        }
      );

    });
}

}

