<div class="jumbotron">
    <div class="display-4">
      <h1 class="display-4">Teilnehmende:n löschen</h1>
      <h2 class="display-6">{{event.title | uppercase}}</h2>
    </div>
    <img src="{{event.imageLink}}" alt="">
  </div>
  <div class="alert alert-danger" role="alert">
  Bist du dir sicher, dass du diesen Eintrag löschen möchtest?<br />
  <a [routerLink]="['/events/custom/pfadi_du_2024/registrations']" class="btn btn-outline-danger">Nein</a>
  <button type="button" class="btn btn-danger" (click)="deleteTN()">Ja</button>
  </div>
  