import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { RouterModule } from '@angular/router';
import { RouterTestingModule } from '@angular/router/testing';

import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { AdminEventCreateComponent } from './admin/events/admin-event-create/admin-event-create.component';
import { AdminEventDeleteComponent } from './admin/events/admin-event-delete/admin-event-delete.component';
import { AdminEventEditComponent } from './admin/events/admin-event-edit/admin-event-edit.component';
import { AdminEventListComponent } from './admin/events/admin-event-list/admin-event-list.component';
import { AdminEventRegistrationListComponent } from './admin/events/registrations/admin-event-registration-list/admin-event-registration-list.component';
import { AdminEventRegistrationCreateComponent } from './admin/events/registrations/admin-event-registration-create/admin-event-registration-create.component';
import { AdminEventRegistrationDeleteComponent } from './admin/events/registrations/admin-event-registration-delete/admin-event-registration-delete.component';
import { AdminEventRegistrationEditComponent } from './admin/events/registrations/admin-event-registration-edit/admin-event-registration-edit.component';
import { AdminUserEditComponent } from './admin/users/admin-user-edit/admin-user-edit.component';
import { AdminUserListComponent } from './admin/users/admin-user-list/admin-user-list.component';
import { AdminUserCreateComponent } from './admin/users/admin-user-create/admin-user-create.component';
import { AdminUserDeleteComponent } from './admin/users/admin-user-delete/admin-user-delete.component';
import { AdminTemplatesDeleteComponent } from './admin/templates/admin-templates-delete/admin-templates-delete.component';
import { AdminTemplatesEditComponent } from './admin/templates/admin-templates-edit/admin-templates-edit.component';
import { AdminTemplatesCreateComponent } from './admin/templates/admin-templates-create/admin-templates-create.component';
import { AdminTemplatesListComponent } from './admin/templates/admin-templates-list/admin-templates-list.component';
import { LoginComponent } from './login/login.component';
import { EventsComponent } from './events/events.component';
import { EventsRegistrations } from './events/events-registrations/events-registrations.component';
import { EventsDashboardComponent } from './events/events-dashboard.component';
import { AdminDashboardComponent } from './admin/admin-dashboard.component';

import {adminRoutes} from './admin/admin.routes';
import {eventsRoutes} from './events/events.routes';
import {AppRoutingModule} from './app.routes';
import { NavbarComponent } from './navbar.component';
import { FooterComponent } from './footer.component';
import { BackendService } from './services/backend.service';
import { EventService } from './services/event.service';
import { TemplateService } from './services/template.service';
import { TemplatePageService } from './services/template-page.service';
import { TemplateFieldService } from './services/template-field.service';
import { RegistrationService } from './services/registration.service';
import { RegistrationTypeService } from './services/registration-type.service';
import { UserService } from './services/user.service';
import { AuthGuardService } from './services/auth.guard.service';
import { StammService } from './services/stamm.service';
import { SubscriptionService } from './services/subscription.service';
import { BezirkService } from './services/bezirk.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminTemplateDashboardComponent } from './admin/templates/admin-template-dashboard/admin-template-dashboard.component';
import { AdminTemplateFieldsComponent } from './admin/templates/admin-template-dashboard/admin-template-fields.component';
import { AdminTemplateCopyComponent } from './admin/templates/admin-template-copy/admin-template-copy.component';
import { AdminTemplatePagesCreateComponent } from './admin/templates/pages/admin-template-pages-create/admin-template-pages-create.component';
import { AdminTemplatePagesDeleteComponent } from './admin/templates/pages/admin-template-pages-delete/admin-template-pages-delete.component';
import { AdminTemplatePagesEditComponent } from './admin/templates/pages/admin-template-pages-edit/admin-template-pages-edit.component';
import { AdminTemplateFieldsEditComponent } from './admin/templates/pages/fields/admin-template-fields-edit/admin-template-fields-edit.component';
import { AdminTemplateFieldsDeleteComponent } from './admin/templates/pages/fields/admin-template-fields-delete/admin-template-fields-delete.component';
import { AdminTemplateFieldsCreateComponent } from './admin/templates/pages/fields/admin-template-fields-create/admin-template-fields-create.component';
import { ChangeDataComponent } from './user/change-data/change-data.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { RegisterComponent } from './register/register.component';
import { EventsRegistrationsSubscriptionComponent } from './events/events-registrations-subscription/events-registrations-subscription.component';
import { AdminUserResetComponent } from './admin/users/admin-user-reset/admin-user-reset.component';
import { AdminEventRegistrationSubscriptionsListComponent } from './admin/events/registrations/subscriptions/admin-event-registration-subscriptions-list/admin-event-registration-subscriptions-list.component';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import{NotifyService} from './services/notify.service';
import { FilterPipe } from './pipes/filter.pipe';
import { ChartsModule } from 'ng2-charts';
import { Settings } from './classes/settings';
import { JwtModule } from '@auth0/angular-jwt';
import { EventsRegistrationSubscriptionAddComponent } from './events/events-registrations-subscription/events-registration-subscription-add/events-registration-subscription-add.component';
import { EventsRegistrationSubscriptionEditComponent } from './events/events-registrations-subscription/events-registration-subscription-edit/events-registration-subscription-edit.component';
import { EventsRegistrationSubscriptionDelComponent } from './events/events-registrations-subscription/events-registration-subscription-del/events-registration-subscription-del.component';
import { PfadiDu2022ListComponent } from './admin/custom/pfadi-du2022-list/pfadi-du2022-list.component';
import { JuffiDu2023ListComponent } from './admin/custom/juffi-du2023-list/juffi-du2023-list.component';
import { RoverDu2023ListComponent } from './admin/custom/rover-du2023-list/rover-du2023-list.component';
import { Ruhrjamb2024Component } from './events/custom/ruhrjamb2024/ruhrjamb2024.component';
import { Ruhrjamb2024AddTNComponent } from './events/custom/ruhrjamb2024/ruhrjamb2024-add-tn/ruhrjamb2024-add-tn.component';
import { Ruhrjamb2024AddStaffComponent } from './events/custom/ruhrjamb2024/ruhrjamb2024-add-staff/ruhrjamb2024-add-staff.component';
import { Ruhrjamb2024DelTNComponent } from './events/custom/ruhrjamb2024/ruhrjamb2024-del-tn/ruhrjamb2024-del-tn.component';
import { Ruhrjamb2024EditTNComponent } from './events/custom/ruhrjamb2024/ruhrjamb2024-edit-tn/ruhrjamb2024-edit-tn.component';
import { Ruhrjamb2024ListComponent } from './admin/custom/ruhrjamb2024-list/ruhrjamb2024-list.component';
import { PfadiAktion2024Component } from './events/custom/pfadi-aktion2024/pfadi-aktion2024.component';
import { PfadiAktion2024AddTNComponent } from './events/custom/pfadi-aktion2024/pfadi-aktion2024-add-tn/pfadi-aktion2024-add-tn.component';
import { PfadiAktion2024DelTNComponent } from './events/custom/pfadi-aktion2024/pfadi-aktion2024-del-tn/pfadi-aktion2024-del-tn.component';
import { PfadiAktion2024EditTNComponent } from './events/custom/pfadi-aktion2024/pfadi-aktion2024-edit-tn/pfadi-aktion2024-edit-tn.component';
import { PfadiAktion2024ListComponent } from './admin/custom/pfadi-aktion2024-list/pfadi-aktion2024-list.component';

import { AdminEventRolesComponent } from './admin/events/admin-event-roles/admin-event-roles.component';
import { AdminEventRegistrationRolesComponent } from './admin/events/registrations/admin-event-registration-roles/admin-event-registration-roles.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component'

export function tokenGetter() {
  return localStorage.getItem(Settings.authAccessTokenName)
}


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AdminEventCreateComponent,
    AdminEventDeleteComponent,
    AdminEventEditComponent,
    AdminEventListComponent,
    AdminEventRegistrationListComponent,
    AdminEventRegistrationCreateComponent,
    AdminEventRegistrationDeleteComponent,
    AdminEventRegistrationEditComponent,
    AdminEventRegistrationSubscriptionsListComponent,
    AdminUserEditComponent,
    AdminUserListComponent,
    AdminUserCreateComponent,
    AdminUserDeleteComponent,
    AdminTemplatesDeleteComponent,
    AdminTemplatesEditComponent,
    AdminTemplatesCreateComponent,
    AdminTemplatesListComponent,
    LoginComponent,
    EventsComponent,
    EventsRegistrations,
    EventsRegistrationsSubscriptionComponent,
    EventsDashboardComponent,
    AdminDashboardComponent,
    NavbarComponent,
    FooterComponent,
    AdminTemplateDashboardComponent,
    AdminTemplateCopyComponent,
    AdminTemplateFieldsComponent,
    AdminTemplatePagesCreateComponent,
    AdminTemplatePagesDeleteComponent,
    AdminTemplatePagesEditComponent,
    AdminTemplateFieldsEditComponent,
    AdminTemplateFieldsDeleteComponent,
    AdminTemplateFieldsCreateComponent,
    RegisterComponent,
    AdminUserResetComponent,
    ChangeDataComponent,
    FilterPipe,
    EventsRegistrationSubscriptionAddComponent,
    EventsRegistrationSubscriptionEditComponent,
    EventsRegistrationSubscriptionDelComponent,
    PfadiDu2022ListComponent,
    JuffiDu2023ListComponent,
    RoverDu2023ListComponent,
    Ruhrjamb2024Component,
    Ruhrjamb2024AddTNComponent,
    Ruhrjamb2024AddStaffComponent,
    Ruhrjamb2024DelTNComponent,
    Ruhrjamb2024EditTNComponent,
    Ruhrjamb2024ListComponent,
    PfadiAktion2024Component,
    PfadiAktion2024AddTNComponent,
    PfadiAktion2024DelTNComponent,
    PfadiAktion2024EditTNComponent,
    PfadiAktion2024ListComponent,
    ChangePasswordComponent,
    AdminEventRolesComponent,
    AdminEventRegistrationRolesComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
    ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    EditorModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    SnotifyModule,
    ChartsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['homestead.test']
      }
    })
  ],
  providers: [
    AuthGuardService,
    BackendService,
    BezirkService,
    EventService,
    NotifyService,
    RegistrationService,
    RegistrationTypeService,
    SnotifyService,
    StammService,
    SubscriptionService,
    TemplateService,
    TemplatePageService,
    TemplateFieldService,
    UserService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
