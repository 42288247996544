import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EventService} from '../../../services/event.service';


@Component({
  selector: 'app-admin-event-delete',
  templateUrl: './admin-event-delete.component.html',
  styleUrls: ['./admin-event-delete.component.css']
})
export class AdminEventDeleteComponent implements OnInit {
  id: any;
   params: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.id = params['id']);
      this.eventService.deleteEvent(this.id).subscribe(
        data => {
          this.router.navigate(['/admin/events']);
        },
        error => console.log(<any>error));
      }

      ngOnDestroy() {
        this.params.unsubscribe();
      }



}
