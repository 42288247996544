import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { BackendService } from './backend.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class AuthGuardService {

  constructor(private backendService: BackendService, private router: Router) { }

   /**
    * Gibt an, ob die im state übergebene url aufgerufen werden kann. Dazu wird überprüft,
    * ob der aufrufende Benutzer am System angemeldet ist oder nicht.
    *
    * @param {ActivatedRouteSnapshot} next
    * @param {RouterStateSnapshot} state
    * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
    * @memberof AuthGuard
    */
   canActivate(
       next: ActivatedRouteSnapshot,
       state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
       return this.checkLogin(state.url);
   }

   /**
    * Überprüft den Anmeldestatus des Benutzers am System. Falls dieser nicht angmeldet ist,
    * wird der Benutzer auf die Login Seite umgeleitet.
    *
    * @param {string} url Ursprünglich aufgerufene Seite, die nach erfolgreichem Login wieder aufgerufen wird
    * @returns {Observable<boolean>}
    * @memberof AuthGuard
    */
   checkLogin(url: string): Observable<boolean> {
       return this.backendService.istAngemeldet.pipe(
           map(angemeldet => {
               if (angemeldet) {
                   return true;
               }

               this.backendService.redirectUrl = url;

               this.router.navigate(['/login']);
               return false;
           })
       );
   }
}
