import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BackendService } from '../services/backend.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  formularwerte: { value: any, valid: boolean } = {} as { value: any, valid: boolean };

 constructor(
    private formBuilder: UntypedFormBuilder,
    private backendService: BackendService,
    private router: Router
  ) { 
    this.formularwerte = this.formBuilder.group({
      email: ['', Validators.required],
   //   geburtsdatum: ['', Validators.required],
  });
  }
  
  forgotPassword({ value, valid }: { value: any, valid: boolean }) {
    this.backendService.forgotPassword(value.email).subscribe(
      data => {
        if (data == true) {
          setTimeout(() => {
            this.router.navigate(['/']);
          }, 2000);
        }
      }
    )

  }

  ngOnInit(): void {
  }

}