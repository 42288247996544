import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TemplateFieldService} from '../../../services/template-field.service';
import { TemplateField } from '../../../models/templateField';
import { TemplatePage } from '../../../models/templatePage';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-template-fields',
  templateUrl: './admin-template-fields.component.html',
  styleUrls: ['./admin-template-fields.component.css']
})
export class AdminTemplateFieldsComponent implements OnInit {
@Input() templatePage: string;
params: any;
templateFields: Observable<TemplateField[]>;
status: any;
message: any;
template_id: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private templateFieldService: TemplateFieldService
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.template_id = params['id']);
    this.templateFields = this.templateFieldService.getTemplateFields(this.templatePage);
  }

}
