import { Component, OnInit } from '@angular/core';
import { PfadiAktion2024Service } from '../../../../services/pfadi-aktion-2024.service';
import { NotifyService } from '../../../../services/notify.service';
import { ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-pfadi-aktion2024-del-tn',
  templateUrl: './pfadi-aktion2024-del-tn.component.html',
  styleUrls: ['./pfadi-aktion2024-del-tn.component.css']
})
export class PfadiAktion2024DelTNComponent implements OnInit {
  event: any;
  params: any;
  subscription_id: any;

  constructor(
    private pfadiAktion2024Service: PfadiAktion2024Service,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifyService: NotifyService
  ) { }


  deleteTN() {
    this.pfadiAktion2024Service.deleteTN(this.subscription_id).subscribe(
      data => {
        this.notifyService.snotifySuccess("Anmeldung erfolgreich gelöscht!")
        this.router.navigate(['/events/custom/pfadiaktion2024/registrations']);
      },
      error => {
        this.notifyService.snotifyError("Beim Löschen der Anmeldung ist ein Fehler aufgetreten!")
        console.log(<any>error);
      });
    }

  ngOnInit(): void {
    this.event = new Array()
    this.event.title = "Pfadi-Diözesanunternehmen"
    this.event.imageLink = "/assets/images/pfadiaktion_2024.jpg";
    this.event.active = 1;

    this.params = this.activatedRoute.params.subscribe(params => this.subscription_id = params['tn_id']);
  }

}
