import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '../services/backend.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  formularwerte: { value: any, valid: boolean } = {} as { value: any, valid: boolean };
  token: any;
  params: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private backendService: BackendService,
    private activatedRoute: ActivatedRoute
  ) { 
    this.formularwerte = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
  });
  }

  resetPassword({ value, valid }: { value: any, valid: boolean }) {
    this.backendService.resetPassword(value.email, value.password, value.password_confirmation, this.token).subscribe(
      data => {
        console.log(data)
        if (data == true) {
          setTimeout(() => {
            this.router.navigate(['/']);
          }, 2000);
        }       
      },
      error => console.log(<any>error)    
      )
  }

  ngOnInit(): void {
    this.params = this.activatedRoute.params.subscribe(params => this.token = params['token']);

  }

}