import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-admin-user-delete',
  templateUrl: './admin-user-delete.component.html',
  styleUrls: ['./admin-user-delete.component.css']
})
export class AdminUserDeleteComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router
  ) { }

  id: any;
   params: any;

   ngOnInit() {
     this.params = this.activatedRoute.params.subscribe(params => this.id = params['id']);
       this.userService.deleteUser(this.id).subscribe(
         data => {
           this.router.navigate(['/admin/users']);
         },
         error => console.log(<any>error));
       }

       ngOnDestroy() {
         this.params.unsubscribe();
       }

}
