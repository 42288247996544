import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-admin-user-reset',
  templateUrl: './admin-user-reset.component.html',
  styleUrls: ['./admin-user-reset.component.css']
})
export class AdminUserResetComponent implements OnInit {

  user: any;
  params: any;
  user_id: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.user_id = params['id']);
    this.user = this.userService.getUser(this.user_id).subscribe(
      data => {
        this.user.email = data['email'];
      },
      error => console.log(<any>error));
  }

  reset(data) {
    this.userService.resetPassword(data).subscribe(
      data => {},
      error => console.log(error)
    )
  }

}
