<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">Passwort zurücksetzen</h1>
  </div>
</div>
<div class="mt-4 col-lg-6 offset-lg-3">
  <div class="card">
    <div class="card-body">
      <form novalidate class="form-horizontal" (ngSubmit)="reset(resetPassword.value)" #resetPassword="ngForm">
    <div class="form-group col-lg-12">
      <label for="inputEmail3" class="col-sm-4 col-form-label">E-Mail-Adresse</label>
      <div class="col-sm-8">
        <input autofocus type="email" name="email" class="form-control" id="inputEmail3" placeholder="E-Mail-Adresse" [(ngModel)]="user.email" #email="ngModel" readonly required>
      </div>
    </div>
    <div class="form-group col-lg-12">
      <div class="col-lg-12">
        <button type="submit" class="btn btn-primary" [disabled]="resetPassword.invalid">Passwort zurücksetzen</button>
      </div>
    </div>
  </form>
</div>
  </div>
</div>
