<div class="jumbotron">
    <div class="display-4">
      <h1 class="display-4">Teilnehmende:n bearbeiten</h1>
      <h2 class="display-6">{{event.title | uppercase}}</h2>
    </div>
    <img src="{{event.imageLink}}" alt="">
  </div>
  <div class="col-md-10 col-md-offset-1">
    <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
    <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
    <div class="well well bs-component">
      <form novalidate class="form-horizontal" (ngSubmit)="editTN(editTNForm.value)" #editTNForm="ngForm">
        <fieldset>
        <div class="card">
            <div class="card-header">Persönliche Daten</div>
            <div class="card-body">
                <div class="form-group">
                    <label for="tn_leitung" class="col-lg-10 control-label">Teilnehmende oder Leitung</label>
                    <div class="col-lg-10">
                    <select class="form-control" rows="2" id="tn_leitung" name="tn_leitung" [ngModel]=null [(ngModel)]="tn.tn_leitung" #tn_leitung="ngModel" required>
                        <option [value]="null" [selected] disabled hidden>Bitte auswählen</option>
                        <option value="tn">Teilnehmende</option>
                        <option value="leitung">Leitung</option>
                    </select>
                    </div>
                </div>
                <div class="form-group">
                    <label for="nachname" class="col-lg-10 control-label">Nachname</label>
                    <div class="col-lg-10">
                    <input type="text" class="form-control" id="nachname" name="nachname" placeholder="Nachname" [(ngModel)]="tn.nachname" #nachname="ngModel" required>
                    <div *ngIf="nachname.errors?.required && nachname.dirty" class="alert alert-danger">
                        Nachname ist ein Pflichtfeld
                    </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="vorname" class="col-lg-10 control-label">Vorname</label>
                    <div class="col-lg-10">
                    <input type="text" class="form-control" id="vorname" name="vorname" placeholder="Vorname" [(ngModel)]="tn.vorname" #vorname="ngModel" required>
                    <div *ngIf="vorname.errors?.required && vorname.dirty" class="alert alert-danger">
                        Vorname ist ein Pflichtfeld
                    </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="geburtsdatum" class="col-lg-10 control-label">Geburtsdatum</label>
                    <div class="col-lg-10">
                    <input type="date" class="form-control" id="geburtsdatum" name="geburtsdatum" placeholder="Geburtsdatum" [(ngModel)]="tn.geburtsdatum" #geburtsdatum="ngModel" required>
                    <div *ngIf="geburtsdatum.errors?.required && geburtsdatum.dirty" class="alert alert-danger">
                        Geburtsdatum ist ein Pflichtfeld
                    </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">Lebensmittelabfragen</div>
            <div class="card-body">
              <div class="form-group">
                <label for="lebensmUnvert" class="col-lg-3 control-label">Unverträglichkeiten</label>
                  <div class="col-lg-10 row" *ngFor="let lebensmUnvertOption of lebensmUnvertOptions">
                    <div class="col-lg-1">
                        <input type="checkbox" id="lebensmUnvertOptions" name="lebensmUnvert" [checked]="lebensmUnvertOption.selected == true" value="{{lebensmUnvertOption.name}}" (change)="updateCheckedlebensmUnvert(lebensmUnvertOption.name, $event)">
                      </div>
                      <div class="col-lg-11">
                      {{lebensmUnvertOption.name}}
                      </div>
                </div>
              </div>
              <div class="form-group">
                <label for="lebensmSonsUnvert" class="col-lg-12 control-label">Sonstige Unverträglichkeiten</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="lebensmSonsUnvert" name="lebensmSonsUnvert" placeholder="Sonstige Unverträglichkeiten" [(ngModel)]="tn.lebensmSonsUnvert" #lebensmSonsUnvert="ngModel">
                  <div *ngIf="lebensmSonsUnvert.errors?.required && lebensmSonsUnvert.dirty" class="alert alert-danger">
                    Sonstige Unverträglichkeiten ist ein Pflichtfeld
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="lebensmVerpf" class="col-lg-3 control-label">Verpflegung</label>
                <div class="col-lg-10 row" *ngFor="let lebensmVerpfOption of lebensmVerpfOptions">
                  <div class="col-lg-1">
                    <input type="checkbox" id="lebensmVerpfOptions" name="lebensmVerpf" [checked]="lebensmVerpfOption.selected == true" value="{{lebensmVerpfOption.name}}" (change)="updateCheckedlebensmVerp(lebensmVerpfOption.name, $event)">
                </div>
                    <div class="col-lg-11">
                    {{lebensmVerpfOption.name}}
                    </div>
              </div>
              </div>
              <div class="form-group">
                <label for="lebensmSonsVerpf" class="col-lg-12 control-label">Sonstige Sonderverpflegung</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="lebensmSonsVerpf" name="lebensmSonsVerpf" placeholder="Sonstige Sonderverpflegung" [(ngModel)]="tn.lebensmSonsVerpf" #lebensmSonsVerpf="ngModel">
                  <div *ngIf="lebensmSonsVerpf.errors?.required && lebensmSonsVerpf.dirty" class="alert alert-danger">
                    Sonstige Sonderverpflegung ist ein Pflichtfeld
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="form-group">
            <div class="col-lg-10 col-lg-offset-2">
              <a [routerLink]="['/events/custom/pfadiaktion2024/registrations']" class="btn btn-default">Abbrechen</a>
              <button type="submit" class="btn btn-primary" [disabled]="editTNForm.invalid">Speichern</button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
  