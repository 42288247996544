import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../../../services/template.service';
import { Router} from '@angular/router';

@Component({
  selector: 'app-admin-templates-create',
  templateUrl: './admin-templates-create.component.html',
  styleUrls: ['./admin-templates-create.component.css']
})
export class AdminTemplatesCreateComponent implements OnInit {

status: any;
  constructor(
    private templateService: TemplateService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  createTemplate(template) {
   this.templateService.addTemplate(template)
   .subscribe(
     template => {
       this.router.navigate(['/admin/templates']);
     },
     error => console.log(<any>error)
   );
 }

}
