<div class="jumbotron">
  <h1 class="display-4">Aktualisiere: {{templatePage.title}}</h1>
</div>
<div class="col-md-10 col-md-offset-1">
  <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
  <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
  <div class="well well bs-component">
    <form novalidate class="form-horizontal" (ngSubmit)="updateTemplatePage(templatePage)" #editTemplatePageForm="ngForm">
      <fieldset>
        <div class="form-group">
          <label for="title" class="col-lg-2 control-label">Seitenname</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" id="title" name="title" placeholder="Seitenname" [(ngModel)]="templatePage.title" #title="ngModel" required>
            <div *ngIf="title.errors?.required && title.dirty" class="alert alert-danger">
              Seitenname ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="description" class="col-lg-2 control-label">Beschreibung</label>
          <div class="col-lg-10">
            <textarea class="form-control" rows="3" id="description" name="description" [(ngModel)]="templatePage.description" #description="ngModel"></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="imageLink" class="col-lg-2 control-label">Bild-URL</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" id="imageLink" name="imageLink" placeholder="Bild-URL" [(ngModel)]="templatePage.imageLink" #imageLink="ngModel">
          </div>
        </div>
        <div class="form-group">
          <div class="col-lg-10 col-lg-offset-2">
            <a [routerLink]="['/admin/template/', template_id]" class="btn btn-default">Abbrechen</a>
            <button type="submit" class="btn btn-primary" [disabled]="editTemplatePageForm.invalid">Aktualisieren</button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
