import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(values: any, filter: any): any {
    if (filter && Array.isArray(values)) {
        return values.filter((value: any) => this.applyFilter(value, filter));

  } else {
    return values;
  }

  }

  applyFilter(value: any, filter: any): boolean {
    let filterKeys = Object.keys(filter);
    return filterKeys.some((keyName) => {
      return new RegExp(filter[keyName], 'gi').test(value[keyName]) || filter[keyName] === "";
    });
  }

}
