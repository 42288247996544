<div class="col-md-12 col-md-offset-1">
  <div class="alert alert-secondary mt-3 text-center" role="alert">
    <h6>Erfassung von Pronomen</h6> 
    <p>Ab sofort könnt Ihr hier im Anmeldesystem Eure Pronomen unter den persönlichen Daten erfassen.</p>
    <p><a href="/user/changeData">Persönliche Daten ändern</a></p>
  </div>
  <div class="row row-cols-1 row-cols-md-3 justify-content-md-center">
  <!-- <div class="col mb-4">
    <div class="card h-100">
      <p><img class="card-img-top" src="assets/images/ruhrjamb2024.png" alt="Ruhrjamb 2024"></p>
      <div class="card-body">
        <p class="card-text"></p>
      </div>
      <div class="card-footer">
        <h5 class="card-title">Ruhrjamb 2024</h5>
        <a [routerLink]="['/events', 'custom', 'ruhrjamb2024', 'registrations']" class="btn btn-primary col-12">Anmeldung</a>
        <a [routerLink]="['/events', 'custom', 'ruhrjamb2024', 'addStaff']" class="btn btn-primary col-12 mt-1">Staff-Anmeldung</a>

      </div>
    </div>
  </div> -->
  <div class="col mb-4">
    <div class="card h-100">
      <p><img class="card-img-top" src="assets/images/pfadiaktion_2024.jpg" alt="Pfadi-Aktion 2024"></p>
      <div class="card-body">
        <p class="card-text">08. - 09. November 2024</p>
      </div>
      <div class="card-footer">
        <h5 class="card-title">Diözesanaktion der Pfadi-Stufe</h5>
        <a [routerLink]="['/events', 'custom', 'pfadiaktion2024', 'registrations']" class="btn btn-primary col-12">Anmeldung</a>
      </div>
    </div>
    </div>
    <div class="col mb-4" *ngFor="let event of events | async">
      <div class="card h-100">
        <p><img class="card-img-top" src="{{backendurl}}images/{{event.imageLink}}" alt="{{event.title}}"></p>
        <div class="card-body">
          <p [innerHTML]="event.description" class="card-text"></p>
        </div>
        <div class="card-footer">
          <h5 class="card-title">{{event.title}} <span *ngIf="event.multipleRegistrations!=true && event.free != 'no matter'" class="badge badge-dpsg">{{event.free}} freie Plätze</span></h5>
          <a [routerLink]="[event.id, 'registrations']" class="btn btn-primary col-12">Anmeldung</a>
        </div>
      </div>

  </div>
  <!-- <div class="col mb-4">
    <div class="card h-100">
      <p><img class="card-img-top" src="assets/images/juffidu2023.png" alt="Juffi-Diözesansommerlager 2023"></p>
      <div class="card-body">
        <p class="card-text"></p>
      </div>
      <div class="card-footer">
        <h5 class="card-title">Juffi-Diözesansommerlager</h5>
        <a [routerLink]="['/events', 'custom', 'juffi_du_2023', 'registrations']" class="btn btn-primary col-12">Anmeldung</a>
        <a [routerLink]="['/events', 'custom', 'juffi_du_2023', 'addStaff']" class="btn btn-primary col-12 mt-1">Staff-Anmeldung</a>

      </div>
    </div>
    </div> -->
    <!-- <div class="col mb-4">
      <div class="card h-100">
        <p><img class="card-img-top" src="{{backendurl}}images/images/roverdu2023.png" alt="Rover-Diözesansommerlager 2023"></p>
        <div class="card-body">
          <p class="card-text"></p>
        </div>
        <div class="card-footer">
          <h5 class="card-title">Roverlænd</h5>
          <a [routerLink]="['/events', 'custom', 'rover_du_2023', 'registrations']" class="btn btn-primary col-12">Anmeldung</a>
        </div>
      </div>
      </div> -->
  </div>
  </div>
  
